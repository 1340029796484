<template>
  <div class="exchange-record">
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">卡券订单</div>
      <div >        
        <div style="position: relative;right: 0px;top: 0px;">
          <div class="Exchange-search">
            <el-input
              type="text"
              placeholder="输入订单编号、卡号"
              v-model="Keyword"
              maxlength="20"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getExchangeRecord()"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>

      <div style="width: 100%" class="ExchangeRecord-table">
        <el-table :data="ExchangeRecord" stripe style="width: 100%">
          <el-table-column
            prop="OrderId"
            label="订单编号"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="CardType"
            label="卡券类型"
            width="80"
          ></el-table-column>
          <el-table-column prop="CardNumber" label="卡号"></el-table-column>
          <el-table-column
            prop="CardOrderTime"
            label="订单时间"
            width="150"
          ></el-table-column>
          <el-table-column prop="CardOrderStatus" label="检验结果" width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.CardOrderStatus == '成功'">
                <span style="color: green">成功</span>
              </div>
              <div v-else>
                <span style="color: red">{{ scope.row.CardOrderStatus }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页条 -->
        <el-pagination
          style="margin-top: 10px"
          v-if="ExchangeRecord.length > 0"
          :pager-count="5"
          :page-sizes="Pagination.pageSizes"
          :page-size="Pagination.pageSize"
          :current-page="Pagination.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Pagination.totalSize"
          @current-change="pageChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </menu-wrapper>
  </div>
</template>


<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";

export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      loading: true,
      ExchangeRecord: [],
      Keyword: "",
      Pagination: {
        pageNumber: 1,
        totalSize: 0,
        pageSize: 20,
        pageSizes: [10, 20, 50, 100],
      },
    };
  },
  activated() {
    this.loading = false;
    if (this.$route.query.orderId) {
        this.Keyword = this.$route.query.orderId;
      }
    this.getExchangeRecord();
  },
  methods: {
    getExchangeRecord() {
      this.$axios
        .get(
          "/api/cardsorder/getexchangerecords?PageSize=" +
            this.Pagination.pageSize +
            "&PageIndex=" +
            this.Pagination.pageNumber +
            "&keyword=" +
            this.Keyword
        )
        .then((res) => {
          //debugger;
          this.ExchangeRecord = res.data.data;
          this.Pagination.totalSize = res.data.rowTotal;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    handleSizeChange(val) {
      this.Pagination.pageSize = val;
      this.Pagination.pageNumber = 1;
      this.getExchangeRecord();
    },
    pageChange(e) {
      this.Pagination.pageNumber = e;
      this.getExchangeRecord();
    },
  },
};
</script>
<style>
.exchange-record .title {
  font-size: 30px;
  color: #757575;
  line-height: 68px;
}
.ExchangeRecord-table td {
  font-size: 13px;
}
.Exchange-search {
  position: absolute;
    top: -66px;
    right: 10px;
}
</style>